@tailwind base;
@tailwind components;

@layer components {
	body {
		font-family: 'Lato', sans-serif;
	}
}
@tailwind utilities;

@layer utilities {
	@responsive {
		.scrollbar-hidden::-webkit-scrollbar {
			display: none;
		}
	}

	.award-bg {
		background-image: url('./svg/AwardBG.svg');
		height: 11rem;
		background-repeat: no-repeat;
		background-position: right bottom;
	}
	.achieve-bg {
		background-image: url('./svg/AchieveBG.svg');
		height: 11rem;
		background-repeat: no-repeat;
		background-position: left bottom;
	}

	.product-bg {
		background-image: url('./svg/AwardBG.svg');
		background-repeat: no-repeat;
		background-position: right bottom;
		min-height: 24rem;
	}

	.pattern-bg {
		background-image: url('../images/wall3D.jpg');
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}

	.waves-bg {
		background-image: url('./svg/Waves.svg');
		background-repeat: no-repeat;
		background-position: center center;
	}

	.awardlightbg {
		background-image: url('./svg/AwardLightBG.svg');
		background-repeat: no-repeat;
		background-position: left bottom;
	}

	.bg-placement {
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}
}
